import { forwardRef } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { TYPOGRAPHY_VARIANTS } from 'theme/typography';

import type { TypographyProps } from '@mui/material';

import styles from './TypographyStyles';

export type Props = Omit<TypographyProps, 'variant'> & {
  display?: string
  variant: typeof TYPOGRAPHY_VARIANTS[number]
};

/**
  This list of variants is future proof through Material UI version 4, as it
  uses the same compatible list from Typography's 'next' propTypes.
 */
const alreadySupportedVariants = [
  'h1',
  'h2',
  'h3',
  'h4',
  'body1',
  'body2',
];

const useStyles = makeStyles({ name: 'Typography' })(styles);

const TypographyComponent = forwardRef<HTMLElement, Props>((props, ref) => {
  // Props
  const { className, variant, display, ...otherProps } = props;

  // Hooks
  const { classes, theme, cx } = useStyles();
  const overrideProps = { className, variant };

  if (alreadySupportedVariants.indexOf(props.variant) === -1) {
    const hasVariant = variant in theme.typography;

    if (hasVariant) {
      overrideProps.variant = 'body1'; // provides font-family
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      overrideProps.className = cx(classes[variant], className);
    } else {
      overrideProps.className = className;
      console.warn(`The variant [${variant}] does not have a custom style definition in Theme.js, and it's not supported Material UI's Typography component.`);
    }
  }

  return (
    <Typography
      {...otherProps}
      {...overrideProps}
      display={display}
      ref={ref}
    />
  );
});

TypographyComponent.defaultProps = {
  display: 'block',
};

TypographyComponent.displayName = 'Typography';

export default TypographyComponent;
