import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import Button from 'components/Buttons/Button';
import TextButton from 'components/Buttons/TextButton';
import Typography from 'components/ui/Atoms/Typography';
import Alert from 'components/ui/Atoms/Icons/AlertLarge';
import styles from './ErrorStyles';

const useStyles = makeStyles({ name: 'ErrorComponent' })(styles);

const ErrorComponent = (props) => {
  const {
    className,
    error,
    recoveryUrl,
    recoveryText,
    onFeedback,
    onRecover,
    ...otherProps
  } = props;
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.root, className)} data-cy={otherProps['data-cy']}>
      <Alert className={classes.icon} />
      <Typography className={classes.error} component="span" variant="h1">
        {error}
      </Typography>
      {recoveryUrl && (
      <Button
        className={classes.link}
        href={recoveryUrl}
        variant="outline"
      >
        {recoveryText}
      </Button>
      )}
      {(onRecover && !recoveryUrl) && (
      <Button
        className={classes.link}
        onClick={onRecover}
        variant="outline"
      >
        {recoveryText}
      </Button>
      )}
      {onFeedback && (
      <TextButton
        className={classes.feedbackLink}
        onClick={onFeedback}
        colorScheme="neutral"
      >
        Report this error
      </TextButton>
      )}
    </div>
  );
};

ErrorComponent.defaultProps = {
  className: undefined,
  onFeedback: undefined,
  onRecover: undefined,
  recoveryUrl: '.',
  error: 'Something went wrong',
  recoveryText: 'Reload',
  'data-cy': 'error',
};

ErrorComponent.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  recoveryUrl: PropTypes.string,
  recoveryText: PropTypes.string,
  onRecover: PropTypes.func,
  onFeedback: PropTypes.func,
  'data-cy': PropTypes.string,
};

export default ErrorComponent;
